import Vue from 'vue'
import Router from 'vue-router'
import HelloWorldVue from '@/components/HelloWorld.vue'
import ChatDialog from '@/views/ChatDialog.vue'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/Index.vue')
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('@/views/UnAuth.vue')
    },
    {
      path: '/hello',
      name: 'hello',
      component: HelloWorldVue
    },
    {
      path: '/chat',
      name: 'chat',
      component: ChatDialog
    }
  ]
})

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App"
};
</script>
<style scoped>
#app {
  height: 100%;
  overflow: hidden;
}
</style>

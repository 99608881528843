import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"; // 引入 store 文件
import VueCookies from 'vue-cookies';

import './assets/css/reset.css'

// 使用Tailwind CSS
import "./assets/css/preflight.css"
import "./assets/css/tailwind.css"

// 使用Element-UI框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });


Vue.use(VueCookies)

Vue.config.productionTip = false

// 全局日期处理
import moment from 'moment'
// 定义全局过滤器实现日期格式化
Vue.filter('dateFormat', function (input, fmtstring) {
  if (!input) return '';
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});
Vue.filter('toDateTime', function (input, fmtstring) {
  if (!input) return '';
  return moment(input).format('YYYY-MM-DD HH:mm:ss');
});
Vue.filter('toDate', function (input, fmtstring) {
  if (!input) return '';
  return moment(input).format('YYYY-MM-DD');
});
Vue.prototype.$moment = moment;



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

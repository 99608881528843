<template>
  <div>
    <div class="chat-dialog">
      <div class="message" v-for="(message, index) in messages" :key="index">
        {{ message }}
      </div>
    </div>
    <div class="input-container">
      <input v-model="inputText" type="text" placeholder="Enter your message" />
      <button @click="startEventStream">Send</button>
    </div>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      inputText: '',
      messages: []
    };
  },
  methods: {
    startEventStream() {
      const prompt = this.inputText; // 替换为你想发送的内容
      const eventSource = new EventSource(`/api/chat?prompt=${encodeURIComponent(prompt)}`);
      eventSource.addEventListener('message', (event) => {
        if (event.data === '[DONE]') {
          eventSource.close();
          console.log('Connection closed by server');
        } else {
          this.messages.push(event.data);
        }
      });
      eventSource.addEventListener('error', (error) => {
        console.error('Connection error:', error);
      });
    },
    mounted() {
      
    }
  }
};
</script>
  
<style>
.chat-dialog {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
}

.input-container {
  margin-top: 10px;
}

.input-container input {
  width: 200px;
  padding: 5px;
}

.input-container button {
  padding: 5px 10px;
}
</style>
  